import axios from 'axios'
import { displayFormSubmitMessage, toggleDisableSubmitBtn } from '@/utils/forms';
// TODO: je suppose qu'il y a la logique autour de l'envoi de la brochure à faire
document.addEventListener("DOMContentLoaded", function () {
    const form: HTMLFormElement | null = document.querySelector(".brochure-form");

    const submitButton: HTMLElement | null = document.querySelector(".submitBrochureForm");

    document.querySelectorAll('.openBrochureForm')?.forEach(item => {
        item?.addEventListener('click', () => {
            form?.classList.toggle('hidden');
        })
    });

    document.querySelector('#closeBrochureForm')?.addEventListener('click', () => {
        form?.classList.add('hidden');
    })

    form?.addEventListener('submit', (event) => {
        console.log('submit brochure form')
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        submitForm(formData);
        // console.log("submitting contact form", formData.entries().toArray());
    })

    const submitForm = async (formData: FormData) => {
        try {
            toggleDisableSubmitBtn(submitButton, true);
            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');

            console.log('response status from brochure form', response.status);
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé');
        } catch (error) {
            console.error(error);
            displayFormSubmitMessage('error', 'Une erreur est survenue lors de l\'envoi de votre message');
        } finally {
            toggleDisableSubmitBtn(submitButton, false);
        }
    }
});
