document.addEventListener("DOMContentLoaded", function () {
    const offerBannerHomepage = document.querySelector(".offer-banner-homepage") as HTMLElement;
    if (offerBannerHomepage) {
        window.addEventListener("scroll", function () {
            const scrollTop: number = window.scrollY;
            if (scrollTop >= 300) {
                offerBannerHomepage.classList.remove("-translate-y-24");
                offerBannerHomepage.classList.remove("opacity-0");
            }
            else {
                offerBannerHomepage.classList.add("-translate-y-24");
                offerBannerHomepage.classList.add("opacity-0");
            }
        });
    }
});
