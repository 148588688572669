import * as gtm from './gtm';

$(function () {
    panel_size();

    lnc_resize();

    $(window).resize(function () {
        panel_size();
        lnc_resize();
    });

    $(".panel").hover(
        function () {
            if ($(window).width() > 992) {
                $(this)
                    .find(".button-square-black")
                    .addClass("button-square-white")
                    .addClass("button-square-hover")
                    .addClass("button-square-white-hover")
                    .removeClass("button-square-black");
            }
            gtm.gae(
                "Tuiles",
                "Survole",
                $(this)
                    .children(".panel_body")
                    .children(".panel_content")
                    .children(".panel_title")
                    .text(),
            );
        },
        function () {
            if ($(window).width() > 992) {
                $(this)
                    .find(".button-square-white")
                    .addClass("button-square-black")
                    .removeClass("button-square-hover")
                    .removeClass("button-square-white-hover")
                    .removeClass("button-square-white");
            }
        },
    );

    $(".block").hover(function () {
        gtm.gae(
            "Liens",
            "Survole",
            $(this).children(".block_link").children(".block_label").text(),
        );
    });
});

function lnc_resize() {
    if ($(window).width() > 1200) {
        $(".panel").each(function () {
            $(this)
                .find(".button-square-white")
                .addClass("button-square-black")
                .removeClass("button-square-white");
        });
    } else {
        $(".panel").each(function () {
            $(this)
                .find(".button-square-black")
                .addClass("button-square-white")
                .removeClass("button-square-black");
        });
    }
}

function panel_size() {
    $(".panel").each(function () {
        $(this).height(($(this).width() / 16) * 8);
        $(this)
            .find(".panel_image")
            .height(($(this).width() / 16) * 8);
    });
}
