document.addEventListener("DOMContentLoaded", function () {
    let pinIndex = 0;

    // Get block with program status
    const avancementBlock: HTMLElement | null = document.querySelector(".programAvancement");
    const programStatus = avancementBlock?.dataset?.programStatus?.split(' ')[0].toLowerCase();

    // Get steps
    const pinsList: Array<HTMLElement> = Array.from(document.querySelectorAll('[data-pin-status]'));

    // Get current step
    const currentPin: HTMLElement | undefined = pinsList?.find((pin, index) => {
        pinIndex = index;
        return pin.dataset.pinStatus === programStatus;
    });

    // Set color for each step
    const steps: NodeListOf<HTMLElement> = document.querySelectorAll('.avancementStep');

    steps.forEach((step, index) => {
        const desc = pinsList[index]?.querySelector('p')
        const icon: SVGElement | null = step.querySelector('svg');
        const paths: NodeListOf<SVGPathElement> | undefined = icon?.querySelectorAll('path');
        const background: HTMLElement | null = step.querySelector('.step-bg');

        if(!background || !paths) return;

        if (currentPin && index <= pinIndex) {
            if (paths) {
                paths.forEach(path => {
                    path.setAttribute('fill', 'white');
                });
            }
            background.style.background = '#640a32';
        } else {
            if(desc) {
                desc.style.fontWeight = "400";
                desc.style.color = "#33333350";
            } 
            if (paths) {
                paths.forEach(path => {
                    path.setAttribute('fill', '#1E1E1E50');
                });
            }
            background.style.background = 'white';
            background.style.border = '1px solid #1E1E1E50';
        }
    });

    // Set width for status line
    const statusLine: HTMLElement | null = document.querySelector('.statusLine');
    if (statusLine) {
        statusLine.style.width = currentPin ? `${currentPin.offsetLeft}px` : '0px';
    }
});
