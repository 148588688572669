document.addEventListener('DOMContentLoaded', () => {

    // DESKTOP
    const menuPanels: NodeListOf<HTMLElement> = document.querySelectorAll('[data-panel-parent-level="0"]');
    const panelWithChildren: NodeListOf<HTMLElement> = document.querySelectorAll('.menu-item-has-children');
    const residencesBlock: HTMLElement | null = document.querySelector('.residences-block');
    const menuCategoryItem: NodeListOf<HTMLElement> = document.querySelectorAll('.menuCategoryItem');
    const searchLocationsInput: NodeListOf<HTMLInputElement> | null = document.querySelectorAll('.menu-search-locations-form');

    // MOBILE
    const mobileMenuToggleBtn: HTMLElement | null = document.querySelector('#mobileMenuToggleBtn');
    const mobileMenu: HTMLElement | null = document.querySelector('#mobile-menu');

    const desktopMenu = new DesktopMenu(residencesBlock, menuPanels, panelWithChildren, menuCategoryItem, searchLocationsInput);
    desktopMenu.init();

    const mobileMenuInstance = new MobileMenu(mobileMenuToggleBtn, mobileMenu,);
    mobileMenuInstance.init();
});

/**
 * Hide menu on program page when scrolled past the header slider
 */
document.addEventListener("DOMContentLoaded", function () {
    const menu = document.querySelector(".menu_topbar") as HTMLElement;
    const programHeaderSlider = document.querySelector("#programHeaderSlider") as HTMLElement;
    let lastScrollTop = 0;

    // To check if we are on a program page
    if (programHeaderSlider) {
        window.addEventListener("scroll", function() {
            let st = window.scrollY || document.documentElement.scrollTop;
            if (menu.classList.contains("menu_topbar-inactive")) {
                // add height menu
                st += 88;
            }
            if (st > lastScrollTop && !menu.classList.contains("menu_topbar-inactive")) {
                menu.classList.add("menu_topbar-inactive");
            } else if (st < lastScrollTop && menu.classList.contains("menu_topbar-inactive")) {
                menu.classList.remove("menu_topbar-inactive");
            }
            lastScrollTop = st <= 0 ? 0 : st;
        });
    }
});

class DesktopMenu {
    constructor(
        private residencesBlock: HTMLElement | null,
        private menuPanels: NodeListOf<HTMLElement>,
        private panelWithChildren: NodeListOf<HTMLElement>,
        private menuCategoryItem: NodeListOf<HTMLElement>,
        private searchLists: NodeListOf<HTMLElement>
    ) {}

    // private debouncedHandleSearch = debounce(async (search: string, inputIndex: number) => {
    //     await this.handleSearch(search, inputIndex);
    // }, 300);

    private closeSeachList() {
        this.searchLists.forEach(item => {
            item.classList.add('hidden');
        })
    }

    init() {
        if (!this.residencesBlock || !this.menuPanels || !this.panelWithChildren) return;

        // Open first category list by default
        document.querySelector('[data-list-items-category]')?.classList.add('show')

        // Hide all menu panels initially
        this.menuPanels.forEach((panel) => {
            panel.classList.add('hidden');
        });

        // Show child panels on mouseover
        this.panelWithChildren.forEach((item) => {
            const panel = item.querySelector(`[data-panel-parent-level="0"]`);
            item.addEventListener('mouseover', () => {
                panel?.classList.remove('hidden');
            });

            item.addEventListener('mouseleave', () => {
                panel?.classList.add('hidden');
            });
        });

        // Toggle category lists on click
        this.menuCategoryItem.forEach((item, index) => {
            const allItems = document.querySelectorAll('[data-list-items-category]') ?? [];
            item.addEventListener('click', () => {
                const btnEl = item.querySelector('.openCategoryList');
                const categoryId = btnEl?.getAttribute('data-category-id') ?? '';
                [...allItems]
                    .filter((category) => category.getAttribute('data-list-items-category') !== categoryId)
                    .forEach((category) => category.classList.remove('show'));

                const list: HTMLElement | null = document.querySelector(`[data-list-items-category="${this.openCategoryListBtn[index].dataset.categoryId}"]`);
                if (list) {
                    list.classList.toggle('show');
                }
            });
        });


        // Add click outside functionality
        document.body.addEventListener('click', (e) => {
            // @ts-expect-error
            if(!e.target?.classList.contains('menu-search-locations-list')) {
                this.closeSeachList();
            }
        }, true);
    }

    private get openCategoryListBtn(): NodeListOf<HTMLElement> {
        return document.querySelectorAll('.openCategoryList');
    }
}

class MobileMenu {
    constructor(private mobileMenuToggleBtn: HTMLElement | null, private mobileMenu: HTMLElement | null) {}

    init() {
        const menuCategories: NodeListOf<HTMLElement> = document.querySelectorAll('[data-menu-item-level="0"]');
        // const residencesCategories: NodeListOf<HTMLElement> = document.querySelectorAll('[data-residences-category]');
        const openResidencesCategoriesBtns: NodeListOf<HTMLElement> = document.querySelectorAll('[data-residences-btn]');
        if (!this.mobileMenuToggleBtn || !this.mobileMenu) return;

        this.mobileMenuToggleBtn.addEventListener('click', () => {
            this.mobileMenu!.classList.toggle('translate-x-full');
            this.mobileMenuToggleBtn?.querySelector('#menu-open')?.classList.toggle('hidden');
            this.mobileMenuToggleBtn?.querySelector('#menu-close')?.classList.toggle('hidden');
        });

        menuCategories?.forEach(category => {
            category.addEventListener('click', () => {
                // console.log('clicked on', category);
                category.classList.toggle('text-red');
                category.querySelector('.open-icon')?.classList.toggle('rotate-180');
                category.querySelectorAll('.mobile-menu-subitem')?.forEach(item => {
                    item.classList.toggle('closed');
                });
            })
        })

        openResidencesCategoriesBtns?.forEach(btn => {
            btn.addEventListener('click', (event) => {
                event?.stopPropagation()
                const category: HTMLElement | null = document.querySelector(`[data-residences-category="${btn.dataset.residencesBtn}"]`);

                if (category) {
                    category.classList.toggle('closed');
                }
            });
        })
    }
}
