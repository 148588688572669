import * as gtm from './gtm';

$(function() {
    return_top_state();

    $(window).on('scroll', function() {
        return_top_state();
    });

    $(".return_top").on('click', function() {
        $("html, body").animate({ scrollTop: 0 }, 750);
        gtm.gae("Return top", "Click");
    });

    $(".return_top_faq").on('click', function() {
        $("html, body").animate(
            { scrollTop: $(".single_body").offset().top - 77 },
            750,
        );
        gtm.gae("Return top", "Click");
    });
});

function return_top_state() {
    if ($(window).height() * 2 > $(document).scrollTop() + $(window).height()) {
        $(".return_top").removeClass("return_top-active");
        $(".return_top_faq").removeClass("return_top_faq-active");
        $(".shortcut_mobile").removeClass("shortcut_mobile-up");
        $(".programs_button").removeClass("programs_button-up");
    } else {
        $(".shortcut_mobile").addClass("shortcut_mobile-up");
        $(".programs_button").addClass("programs_button-up");
        $(".return_top").addClass("return_top-active");
        $(".return_top_faq").addClass("return_top_faq-active");
    }

    if ($(window).width() > 992) {
        if ($(window).scrollTop() + $(window).height() >= $(".bottombar")?.offset()?.top) {
            $(".return_top").addClass("return_top-static");
            $(".return_top_faq").addClass("return_top_faq-static");
        } else {
            $(".return_top").removeClass("return_top-static");
            $(".return_top_faq").removeClass("return_top_faq-static");
        }
    }
}