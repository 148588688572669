import axios from "axios"
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

document.addEventListener('DOMContentLoaded', () => {
    const immodvisorsContainer = document.querySelector('.immodvisors-container');
    if(immodvisorsContainer) {
        console.log('block is here');
        getImmodvisorsReview()
        getImmodvisorsRating()

    }

    new Splide(`.immodvisors-container`, {
        type: 'loop',
        perPage: window.innerWidth < 768 ? 1 : 3,
        classes: {
            pagination: 'splide__pagination immodvisors__pagination block',
            page: 'splide__pagination__page',
        },
    }).mount();
})

const getImmodvisorsReview = async () => {
    try {
        //@ts-ignore
        const response = await axios.get(`${urls.ajax}?action=fetch_reviews_from_api`)
        console.log('response from immodvisors reviews', response);
    } catch (error) {
        console.error(error);
    }
}

const getImmodvisorsRating = async () => {
    try {
        //@ts-ignore
        const response = await axios.get(`${urls.ajax}?action=fetch_rating_from_api`)

        if(response.status !== 200) {
            throw new Error('Error fetching immodvisors rating');
        }

        const data = response.data.datas?.company;
        const lncImmodvisorsBlock: HTMLElement | null = document.querySelector('.immodvisors-widget-container');

        if(!lncImmodvisorsBlock || !data) return;

        lncImmodvisorsBlock.classList.remove('hidden');

        const immodvisorsRating: HTMLElement | null = document.querySelector('.immodvisors-rating');
        const immodvisorsRatingStars: SVGElement | null = document.querySelector('.immodvisors-rating-stars');
        if(immodvisorsRating) {
            immodvisorsRating.textContent = `${data?.rating}/5`;
            for(let i = 0; i < Math.ceil(data?.rating); i++) {
                immodvisorsRatingStars?.children[i].classList.remove('hidden');
            }
        }

        const immodvisorsRatingCount: HTMLElement | null = document.querySelector('.immodvisors-reviews');
        if(immodvisorsRatingCount) {
            immodvisorsRatingCount.textContent = `${data?.reviews?.recommended || data?.nbr_reviews_google} avis clients`;
        }


    } catch (error) {
        console.error(error);
    }
}
