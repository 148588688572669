// Default theme
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export class HomepageHeroSlider {
    private sliderSelector!: String;
    private sliderContainer: HTMLElement;
    private sliderItems!: Array<HTMLElement>;
    private currentIndex!: number;
    private sliderTextContent: NodeListOf<HTMLElement> | undefined;

    constructor(private sliderId: String) {
        this.sliderContainer = document.querySelector(`[data-slider-id="${this.sliderId}"]`)!;

        if (!this.sliderContainer) return;
        this.sliderSelector = sliderId

        // Slider images
        this.sliderItems = Array.from(this.sliderContainer.querySelectorAll('.slider-item'));
        
        // Homepage slider text content
        this.sliderTextContent = document.querySelectorAll('[data-slide-content-id]');

        this.currentIndex = 0;

        this.setupSplideSlider();
    }

    private setSliderHeight = (slider: String) => {
        if(slider === 'programHeaderSlider' || slider === 'programContactSlider') {
            return '350px';
        } else {
            return '100vh';
        }
    }

    private async openFullscreen(slide: HTMLImageElement): Promise<void> {
        const fullscreenElement = document.fullscreenElement;
    
        if (!fullscreenElement) {
            slide.requestFullscreen();
        } else {
            slide.style.display = 'none';
            await document.exitFullscreen();
        }
      }

    private setupSplideSlider(): void {
        const slider = new Splide(`.${this.sliderSelector}`, {
            type: 'loop',
            mediaQuery: 'min',
            breakpoints: {
                0: {
                    height: this.setSliderHeight(this.sliderSelector),
                    width: '100vw',
                },
                768: {
                    height: '100vh',
                    // heightRatio: 1,
                }
            },
            cover: true,
            classes: {
                pagination: 'splide__pagination slider-pagination-container',
                page      : 'splide__pagination__page slider-pagination-items',
            },
            // arrowPath: "M1 1L24 24L1 47"
        }).mount();

        slider.on('move', (newIndex, prevIndex) => {
            if(newIndex > prevIndex) {
                this.changeSlide(1, newIndex);
            } else {
                this.changeSlide(-1, newIndex);
            }
        })

        if(this.sliderId === 'programHeaderSlider') {
            slider.on('click', (slide) => {
                console.log(slide)
                // Display slide in fullscreen
                const image = slide.slide.querySelector('img');
                if(image) {
                    image.style.display = 'block';
                    this.openFullscreen(image);
                }
            })
        }

    }

    private changeSlide(direction: number, currentIndex: number): void {
        this.currentIndex += direction;

        if (this.currentIndex < 0) this.currentIndex = this.sliderItems.length;
        if (this.currentIndex >= this.sliderItems.length) this.currentIndex = 0;

        // this.showItem(this.currentIndex);
        this.sliderTextContent?.forEach((el, idx) => {
            if (idx === currentIndex) {
                el.classList.remove('translate-y-full');
                el.classList.remove('opacity-0');
            } else {
                el.classList.add('translate-y-full');
                el.classList.add('opacity-0');
            }
        })
    }

    public static initSliders(): void {
        document.addEventListener('DOMContentLoaded', () => {
            document.querySelectorAll('[data-slider-id]').forEach(el => {
                const sliderId = el.getAttribute('data-slider-id');
                if (sliderId) {
                    new HomepageHeroSlider(sliderId)
                }
            });
        });
    }
}

HomepageHeroSlider.initSliders();
