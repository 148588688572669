import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSearchFormStore = defineStore('searchForm', () => {
    // STATE
    const pricesList = [
        "Non précisé",
        "Moins de 100 000€",
        "Entre 100 000€ et 200 000€",
        "Entre 200 000€ et 300 000€",
        "Entre 300 000€ et 400 000€",
        "Entre 400 000€ et 500 000€",
        "Entre 500 000€ et 600 000€",
        "Entre 600 000€ et 700 000€",
        "Entre 700 000€ et 800 000€",
        "Entre 800 000€ et 900 000€",
        "Plus de 900 000€",
    ]

    const typesList = [
        "Appartement",
        "Maison",
        "Terrain",
        "Parking",
        "Local commercial",
        "Bureau",
        "Immeuble",
        "Fonds de commerce",
        "Autre",
    ]

    const roomsList = [
        "Studio",
        "2 pièces",
        "3 pièces",
        "4 pièces",
        "5 pièces et +",
    ]

    const dispositifsList = [
        {
            key: "PTZ",
            value: "ptz"
        },
        {
            key: "PINEL",
            value: "pinel"
        },
        {
            key: "PINEL+",
            value: "pinel+"
        },
        {
            key: "TVA réduite",
            value: "tva-reduite"
        },
        {
            key: "Prix maitrisés",
            value: "prix-maitrises"
        },
        {
            key: "Nue-propriété",
            value: "nue-propriete"
        }
    ]

    const avancementList = [
        {
            key: 'Avant-première',
            value: 'avant-premiere'
        },
        {
            key: 'En commercialisation',
            value: 'commercialisation'
        },
        {
            key: 'Travaux en cours',
            value: 'travaux'
        },
        {
            key: 'Livraison immédiate',
            value: 'livraison'
        }
    ]

    const searchQuery = ref('')

    const userLocation = ref({
        latitude: 48.8566 as number,
        longitude: 2.3522 as number,
        distance: 50 as number,
    })

    const searchPagination = ref({
        currentPage: 1,
        maxPage: 1,
        totalCount: 0,
    });

    const formPayload = ref({
        types: [] as ProgramType[],
        rooms: [] as ProgramRoom[],
        price: '' as ProgramPrice,
        dispositifs: [] as ProgramDispositif[],
        status: [] as ProgramStatus[],
        places: [] as ProgramPlace[],

        perPage: 16 as number,
    });

    // METHODS
    const setFormPayload = (value: any) => {
        formPayload.value = value;
    }

    const resetPagination = () => {
        searchPagination.value = {
            currentPage: 1,
            maxPage: 1,
            totalCount: 0,
        }
    }

    const resetFormPayload = () => {
        formPayload.value = {
            types: [] as ProgramType[],
            rooms: [] as ProgramRoom[],
            price: '' as ProgramPrice,
            dispositifs: [] as ProgramDispositif[],
            status: [] as ProgramStatus[],
            places: [] as ProgramPlace[],

            perPage: 16 as number,
        };

        resetPagination();

        userLocation.value = {
            latitude: 48.8566 as number,
            longitude: 2.3522 as number,
            distance: 50 as number,
        }
    }

    // QUERY BUILDER
    // const updateSearchQuery = (key: string, value: string | number | [] | string[] | ProgramPlace[] | ProgramRoom[]) => {
    const updateSearchQuery = (useDistance: boolean = false) => {
        const params = new URLSearchParams();

        Object.entries(formPayload.value).forEach(([key, value]) => {
            if(!!value) {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        if (key === 'places') {
                            // @ts-expect-error
                            params.append(`${key}[${index}]`, String(item?.value));
                        } else {
                            params.append(`${key}[${index}]`, String(item));
                        }
                    });
                } else {
                    params.append(key, String(value));
                }
            }
        });

        if(useDistance) {
            params.append('latitude', String(userLocation.value.latitude));
            params.append('longitude', String(userLocation.value.longitude));
            params.append('distance', String(userLocation.value.distance));
        }

        params.append('page', String(searchPagination.value.currentPage));
        searchQuery.value = params.toString();
    }

    return {
        pricesList,
        typesList,
        roomsList,
        dispositifsList,
        avancementList,
        formPayload,
        userLocation,
        searchPagination,
        searchQuery,
        updateSearchQuery,
        setFormPayload,
        resetPagination,
        resetFormPayload
    };
}, { persist: {
        storage: sessionStorage,
        pick: ['formPayload'],
    }
})
