// import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';
import { HomepageHeroSlider } from '../homepage/hero-slider';
import { OffersSlider } from '../offers-slider';
import axios from 'axios';
import { displayFormSubmitMessage, toggleDisableSubmitBtn } from '@/utils/forms';

import { gtmEvent } from '../../../scripts/includes/gtm';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;

const getFormAttributes = (element: HTMLElement) => {
    const form: HTMLElement | null = element.querySelector('form');

    if(!form) return;

    gtmIdOperation = form?.getAttribute('data-program-id-operation');
    gtmProgramName = form?.getAttribute('data-program-name');
}

const setGtmLeadEvent = (success: boolean) => {
    gtmEvent('Lead', {
        form: {
            id: "Rappel form",
            title: "Rappel form"
        },
        program: {
            id: gtmIdOperation ?? '',
            title: gtmProgramName ?? '',
        },
        success: success
    });
}

document.addEventListener("DOMContentLoaded", function () {
    const submitButton: HTMLButtonElement | null = document.querySelector(".submitRappelForm");

    const programForm: HTMLElement | null = document.querySelector(
        "#rappelContactFormDialog",
    );

    
    const closeRappelContactFormDialog: HTMLElement | null = document.querySelector(
        "#closeRappelContactFormDialog",
    );
    
    if (!programForm) return;
    
    let sliderType: String | null
    
    getFormAttributes(programForm);

    const closeForm = () => {
        programForm?.classList.add("hidden");
        document.querySelector('body')?.classList.remove("overflow-hidden");
        if (sliderType) {
            programForm?.querySelector(`[data-slider-type="${sliderType}"]`)?.classList.add('hidden');
        } else {
            programForm?.querySelector('.fallbackImage')?.classList.add('hidden');
        }
    }

    document.querySelectorAll(".openRappelForm")?.forEach((element) => {
        element?.addEventListener("click", () => {
            programForm?.classList.toggle("hidden");
            document.querySelector('body')?.classList.add("overflow-hidden");

            sliderType = element.getAttribute("data-slider-type");
            const targetSection = programForm?.querySelector(`[data-slider-type="${sliderType}"]`);
            if (targetSection) {
                let slider
                if(sliderType === 'image') {
                    slider = new HomepageHeroSlider('.programContactOffersSlider')
                    if(slider) targetSection.classList.remove('hidden');
                } else {
                    slider = new OffersSlider()
                    targetSection.classList.remove('hidden');
                }
            } else {
                programForm?.querySelector('.fallbackImage')?.classList.remove('hidden');
            }
        });
    });

    closeRappelContactFormDialog?.addEventListener("click", () => {
        closeForm();
    });

    programForm?.addEventListener("submit", async (event) => {
        try {
            toggleDisableSubmitBtn(submitButton, true);
            event.preventDefault();

            const formData = new FormData(event.target as HTMLFormElement);
            
            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);
            console.log('response status from rappel contact form', response.status);

            if(response.status !== 200) throw new Error('Failed to submit form');
            
            setGtmLeadEvent(true);
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé, nous vous rappelerons dans les plus brefs délais');
        } catch (error) {
            console.error(error);
            displayFormSubmitMessage('error', 'Une erreur est survenue, veuillez réessayer plus tard');
            setGtmLeadEvent(false);
        } finally {
            setTimeout(() => {
                toggleDisableSubmitBtn(submitButton, false);
                closeForm();
            }, 5000);
        }
    });
});
