import * as gtm from './gtm';

$(function () {
    $(window).scroll(function () {
        if ($("body").hasClass("home")) {
            if ($(".header").height() - 74 < $(document).scrollTop()) {
                $(".topbar").removeClass("topbar-static");
            } else {
                $(".topbar").addClass("topbar-static");
            }
        }
    });

    $(".menu_wrapper > .menu_item > .sub-menu").wrapInner(
        '<div class="sub-menu_wrapper"></div>',
    );

    /*
    $('.topbar .menu_item-sub-menu > .menu_link').each(function () {
        $(this).after('<button class="menu_item_button" type="button" role="button"><svg class="menu_item_icon"><use xlink:href="' + theme_directory_uri + '/build-mix/svg/sprite.svg#drop_down"></use></svg></button>');
    });
    */

    $(".topbar_button").click(function (event) {
        event.stopPropagation();
        $(".menu").toggleClass("menu-open");
        gtm.gae("Menu", "Affiche");
    });

    $(".menu_button").click(function (event) {
        event.stopPropagation();
        $(".menu").toggleClass("menu-open");
        gtm.gae("Menu", "Cache");
    });

    /*
    $('.menu_item_button').click(function (event) {
        event.stopPropagation();
        $(this).parent().children('.sub-menu').toggleClass('sub-menu-open');
        $(this).toggleClass('menu_item_button-active');
            gtm.gae('Menu', 'Affiche', $(this).parent().children('.menu_link').text());
    });
    */

    $(document).click(function () {
        $(".menu-open").removeClass("menu-open");
        gtm.gae("Menu", "Cache");
    });

    $(".topbar .brand").click(function () {
        gtm.gae("Menu", "Clique", "Logo");
    });

    $(".topbar .links_item-search").click(function () {
        gtm.gae("Menu", "Clique", "Recherche");
    });

    $(".topbar .links_item-login").click(function () {
        gtm.gae("Menu", "Clique", "Connexion");
    });

    $(".topbar .links_item-contact").click(function () {
        gtm.gae("Menu", "Clique", "Contact");
    });

    $(".topbar .phone").click(function () {
        gtm.gae("Menu", "Clique", "Téléphone");
    });

    $(".bottombar .brand").click(function () {
        gtm.gae("Pied de page", "Clique", "Logo");
    });

    $(".bottombar .links_item-linkedin").click(function () {
        gtm.gae("Pied de page", "Clique", "Linkedin");
    });

    $(".bottombar .links_item-facebook").click(function () {
        gtm.gae("Pied de page", "Clique", "Facebook");
    });

    $(".bottombar .links_item-youtube").click(function () {
        gtm.gae("Pied de page", "Clique", "Youtube");
    });

    $(".bottombar .phone").click(function () {
        gtm.gae("Pied de page", "Clique", "Téléphone");
    });

    $(".menu_item-sub-menu").hover(
        function () {
            gtm.gae("Menu", "Survole", $(this).children(".menu_link").text());
        },
        function () {},
    );
});
