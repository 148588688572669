<script setup>
import {ref, computed, onMounted, watch, nextTick, watchEffect, toRaw} from 'vue';
import axios from 'axios'
import { gae } from '../../scripts/includes/gtm';

const props = defineProps({
    programs: {
        type: Array,
        default: [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    displayedComponent: {
        type: String,
        default: 'all',
    },
});

const loadingMap = ref(true)
const mapBoxRef = ref('map_box_search')
const markers = ref([]);
const cluster = ref(null);
const map = ref(null);
const mapObject = ref(null);
const mapInfoWindow = ref(null);
const retries = ref(0)
const isMapInitialized = ref(false)

const emits = defineEmits([
    "update:programs",
    "update:loading"
])

const computedPrograms = computed(() => props.programs.filter(program => program.latitude !== "0" && program.longitude !== "0"))

const computedLoading = computed({
    get() {
        return props.loading
    },
    set(value) {
        emits("update:loading", value)
    }
})

async function getMarkersProgramDetails(marker, program) {
    if(!program) {
        console.error('No program found !');
        return;
    }
    
    try {
        let data = new FormData();
        data.append("action", "search_infowindow");
        data.append("id", program.id);

        const response = await axios.post(urls.ajax, data);
        if(response.status !== 200) throw new Error('Error getting program data for map')

        return response.data

    } catch (error) {
        console.error('Error while getting program details', error);   
    }
}

async function setMarkerClusterer(map, markers) {
    if(!markers || !map) {
        console.error('No markers or map found')
        return
    }

    // Clear existing cluster if any
    if(cluster.value) {
        cluster.value.clearMarkers();
        cluster.value = []
    }
    
    try {
        cluster.value = await new MarkerClusterer(
            map,
            markers,
            {
                imagePath: urls.theme + "/assets/images/cluster.png",
                styles: [
                    {
                        url: urls.theme + "/assets/images/cluster.png",
                        height: 24,
                        width: 24,
                        textColor: "#FFFFFF",
                    },
                    {
                        url: urls.theme + "/assets/images/cluster.png",
                        height: 24,
                        width: 24,
                        textColor: "#FFFFFF",
                    },
                    {
                        url: urls.theme + "/assets/images/cluster.png",
                        height: 24,
                        width: 24,
                        textColor: "#FFFFFF",
                    },
                    {
                        url: urls.theme + "/assets/images/cluster.png",
                        height: 24,
                        width: 24,
                        textColor: "#FFFFFF",
                    },
                    {
                        url: urls.theme + "/assets/images/cluster.png",
                        height: 24,
                        width: 24,
                        textColor: "#FFFFFF",
                    },
                ],
                minimumClusterSize: 5,
            }
        )
    await nextTick()
        
        if(cluster.value) {
            cluster.value.fitMapToMarkers()
        }
        
    } catch (error) {
        console.error(error)
    }
}

async function setMarkers(map) {
    const markers = []
    
    if(!map) {
        setTimeout(() => {
            setMarkers()
        }, 1000)
    } else {
        if(!!cluster.value) {
            cluster.value.clearMarkers()
        }

        computedPrograms.value.forEach((program, index) => {
            const location = {
                lat: parseFloat(program.latitude),
                lng: parseFloat(program.longitude),
                title: program.name,
            };
    
            const marker = new f4.map.Marker({
                position: {
                    lat: location.lat,
                    lng: location.lng,
                },
                map: map,
                title: location.title || index + 1,
                zIndex: 80,
                icon: {
                    url:
                        urls.theme +
                        "/assets/images/placeholder.png",
                },
            });
    
            if(!marker) return
    
            marker.addListener('mouseover', async () => {
                gae(
                    "Carte des programmes",
                    "Survole",
                    program.id
                );

                const details = await getMarkersProgramDetails(marker, program)

                if(!details) {
                    console.error('No details found')
                    return
                }

                mapInfoWindow.value.setContent(
                    details
                );

                mapInfoWindow.value.open(
                    map,
                    marker
                );
                
            });

            marker.addListener('mouseout', () => {
                mapInfoWindow.value.close()
            });

            marker.addListener('click', () => {
                gae(
                    "Carte des programmes",
                    "Clique",
                    program.id
                );
                window.location.href = program.permalink;
            });

            markers.push(marker)
        })

        // Create cluster
        if(!!markers.length && !!map) {

            await setMarkerClusterer(map, markers)

            loadingMap.value = false   
        } else {
            console.error('No markers found')
            return
        }
    }
}

async function initMap() {
    try {
        map.value = await kimono.addMap({
            container: "#map_box_search",
            zoom: 6,
            theta: 55,
            phi: 5,
            lon: 2.333333,
            lat: 48.866667,
        })

        mapInfoWindow.value = await new f4.map.InfoWindow({
            content: "",
            closeButton: false,
            zIndex: 100,
        })

        map.value.on('load', async (realEstateAd, mapElement) => {
            mapObject.value = mapElement
            isMapInitialized.value = true

            await setMarkers(mapElement)
        })

    } catch (error) {
        retries.value++;
        if(retries.value < 4) {
            setTimeout(() => {
                initMap()
            }, 200)
        }
    }
}

watch(() => props.displayedComponent, async (newVal, oldVal) => {
    if(!!map.value) {
        window.setTimeout(() => {
            f4.map.event.trigger(map.value, 'resize')
        }, 300)
    }
}, { deep: true });

watch(
    () => props.programs,
    async (newVal, oldVal) => {
        if (newVal && newVal !== oldVal && isMapInitialized.value) {
            const map = mapObject.value;
            await setMarkers(toRaw(map));
        }
    },
    { deep: true }
);

onMounted(() => {
    if (!isMapInitialized.value && props.programs.length) {
        initMap();
    }
});

</script>

<template>
    <div v-if="loadingMap" class="absolute inset-0 mx-auto animate-pulse">Chargement...</div>
    <div class="w-auto h-full">
        <div class="w-full h-full" id="map_box_search" ref="map_box_search"></div>
    </div>
</template>


<style>
#map_box_search {
    width: 100%;
}

.kimono-map .f4map {
    width: 100%;
} 
</style>