<script setup lang="ts">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
    placeholder: {
        type: String,
        default: 'Rechercher'
    },
    type: {
        type: String,
        default: 'text'
    },
    name: {
        type: String,
        default: ''
    },
    theme: {
        type: String,
        default: 'light'
    },
    required: {
        type: Boolean,
        default: false
    },
});

const model = defineModel({
    default: '',
});

const focus = ref(false);
const container = ref(null);

onClickOutside(container, () => {
    focus.value = false;
});
</script>

<template>
    <div ref="container" class="flex flex-col relative">
        <label :for="props.name"
            :class="`uppercase ${props.theme === 'light' ? 'text-dark' : 'text-white'} opacity-50 absolute ${focus || model || type === 'date' ? 'text-xs -top-3' : 'text-base top-2.5'} transition-all duration-150 ease-in-out`">
            {{ props.placeholder }}
        </label>
        <input :id="props.name" :name="props.name" :type="props.type"
            :class="`border-b ${props.theme === 'light' ? 'border-secondary text-dark' : 'border-white text-white'} py-2 outline-none bg-transparent`"
            @focus="focus = true" v-model="model" autocomplete="off" :required="props.required">
    </div>
</template>
