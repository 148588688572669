// import Splide from '@splidejs/splide';
// import '@splidejs/splide/css';
import { HomepageHeroSlider } from '../homepage/hero-slider';
import { OffersSlider } from '../offers-slider';
import axios from "axios";
import { gtmEvent } from '../../../scripts/includes/gtm';
import { displayFormSubmitMessage } from '@/utils/forms';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;

const getFormAttributes = (element: HTMLElement) => {
    const form = element.querySelector('form');

    if(!form) return;

    gtmIdOperation = form?.getAttribute('data-program-id-operation');
    gtmProgramName = form?.getAttribute('data-program-name');
}

const setGtmLeadEvent = (success: boolean) => {
    gtmEvent('Lead', {
        form: {
            id: "Contact form",
            title: "Contact form"
        },
        program: {
            id: gtmIdOperation ?? '',
            title: gtmProgramName ?? '',
        },
        success: success
    });
}

document.addEventListener("DOMContentLoaded", function () {
    const programFormDialog: HTMLElement | null = document.querySelector(
        "#programContactFormDialog",
    );

    const defaultForm: HTMLElement | null = document.querySelector('.defaultForm');
    const bookletForm: HTMLElement | null = document.querySelector('.bookletForm');

    const openFormButton: HTMLElement | null = document.querySelector(
        "#closeProgramContactFormDialog",
    );

    let sliderType: String | null

    // Submit form
    const submitForm = async (data: FormData) => {
        try {
            //@ts-ignore
            const response = await axios.post(urls.ajax, data)
            
            if(response.status !== 200) {
                throw new Error('Error submitting program contact form');
            }

            console.log('response status from program contact form', response.status);
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé');
            setGtmLeadEvent(true);
        } catch (error) {
            displayFormSubmitMessage('error', 'Une erreur est survenue lors de l\'envoi de votre message');
            setGtmLeadEvent(false);
            console.error(error);
        }
    }

    document.querySelectorAll(".openProgramForm")?.forEach((element) => {
        element?.addEventListener("click", () => {
            console.log("open program form", bookletForm, defaultForm);
            programFormDialog?.classList.toggle("hidden");

            sliderType = element.getAttribute("data-slider-type");

            if(sliderType === 'booklet') {
                bookletForm?.classList.toggle("hidden");
                if(bookletForm) getFormAttributes(bookletForm);
            } else {
                defaultForm?.classList.toggle("hidden");
                if(defaultForm) getFormAttributes(defaultForm);
            }

            const targetSection = programFormDialog?.querySelector(`[data-slider-type="${sliderType}"]`);

            if (targetSection) {
                let slider
                if(sliderType === 'image') {
                    slider = new HomepageHeroSlider('.programContactOffersSlider')
                    if(slider) targetSection.classList.remove('hidden');
                } else if (sliderType === 'booklet') {
                    targetSection.classList.remove('hidden');
                } else {
                    slider = new OffersSlider()
                    targetSection.classList.remove('hidden');
                }
            } else {
                programFormDialog?.querySelector('.fallbackImage')?.classList.remove('hidden');
            }
        });
    });

    openFormButton?.addEventListener("click", () => {
        programFormDialog?.classList.add("hidden");
        bookletForm?.classList.add("hidden");
        defaultForm?.classList.add("hidden");
        const targetSection = programFormDialog?.querySelector(`[data-slider-type="${sliderType}"]`);
        const fallbackImage = programFormDialog?.querySelector('.fallbackImage');
        if (sliderType) {
            targetSection?.classList.add('hidden');
            fallbackImage?.classList.add('hidden');
        } else {
            fallbackImage?.classList.add('hidden');
        } 
    });

    programFormDialog?.addEventListener("submit", (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        submitForm(formData);
        // console.log("submitting form", Array.from(formData.entries()));
    });
});
