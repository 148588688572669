import { ref, watch } from 'vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useSearchFormStore } from '@/stores/searchFormStore';
import { formatSearchResults } from '@/utils/format';
import { debounce } from '@/utils/forms';

export const useSearchForm = () => {
    const searchStore = useSearchFormStore();
    const { formPayload, searchQuery } = storeToRefs(searchStore);

    const loading = ref(false);
    const programsCount = ref(0);

    const getProgramsCount = async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs_count&${searchQuery.value}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const loadPrograms = async () => {
        const response = await getProgramsCount();
        programsCount.value = response.programs_count;
    };

    const setSearchUrl = async () => {
        window.location.href = `/immobilier-neuf/?${searchQuery.value}`;
    };

    const getProgramsFromSearch = async () => {
        loading.value = true;

        const response = await axios
            .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=search_programs&${searchQuery.value}`);

        loading.value = false;

        return {
            programs: response.data.programs,
            pagination: response.data.pagination,
        }
    };

    const debouncedCallback = debounce(() => {
        getProgramsCount().then((response) => {
            programsCount.value = response.programs_count;
        });
    }, 100);

    watch(() => searchQuery.value,
        () => {
            debouncedCallback()
            console.log(searchQuery.value);
        },
    );

    watch(() => formPayload.value, () => {
        searchStore.updateSearchQuery();
    }, { deep: true });

    return {
        loading,
        programsCount,
        loadPrograms,
        setSearchUrl,
        getProgramsFromSearch,
        formatSearchResults
    };
};
