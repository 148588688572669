<template>
    <!-- card -->
    <div class="col-span-1 pt-4 animate-pulse">
        <div class="h-full flex flex-col justify-between bg-white rounded-lg">
            <div>
                <!-- header -->
                <div class="w-full h-64 bg-gray-300 rounded-lg"></div>
                <!-- body -->
                <div class="p-4 space-y-2">
                    <div class="w-32 h-6 bg-gray-300"></div>
                    <div class="w-40 h-4 bg-gray-200"></div>
                    <div class="w-28 h-4 bg-gray-100"></div>
                </div>
            </div>
            <!-- footer -->
            <div class="p-4">
                <div class="w-32 h-10 px-3 py-2 inline-flex font-bold bg-primary/50 rounded"></div>
            </div>
        </div>
    </div>
</template>
