document.addEventListener("DOMContentLoaded", function () {
    const faqBtns: NodeListOf<HTMLElement> = document.querySelectorAll(
        ".displayFaqQuestionBtn",
    );

    faqBtns.forEach((btn: HTMLElement) => {
        btn.addEventListener("click", function () {
            // Close all other open FAQ items
            faqBtns.forEach((otherBtn: HTMLElement) => {
                if (otherBtn !== btn) {
                    otherBtn.querySelector("svg")?.classList.remove("rotate-180");
                    otherBtn.querySelector("svg")?.classList.remove("stroke-white");
                    otherBtn.nextElementSibling?.classList.add("hidden");
                    otherBtn.classList.remove("bg-primary");
                    otherBtn.classList.remove("text-white");
                }
            });

            // Toggle the clicked FAQ item
            btn.querySelector("svg")?.classList.toggle("rotate-180");
            btn.querySelector("svg")?.classList.toggle("stroke-white");
            btn.nextElementSibling?.classList.toggle("hidden");
            btn.classList.toggle("bg-primary");
            btn.classList.toggle("text-white");
        });
    });
});
