const handleTextInputLabel = () => {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('.relative input');
    const toggleClass: string = '-translate-y-4';
    inputs.forEach((input:HTMLInputElement) => {
        const label = input.nextElementSibling as HTMLElement;
        
        if(label && label.tagName !== 'LABEL') return;

        input.addEventListener('focus', () => {
            label.classList.add(toggleClass);
        });

        input.addEventListener('blur', () => {
            if (!input.value.trim()) {
                if(!input.value) label.classList.remove(toggleClass);
            }
        });

        // Check initial state
        if (input.value.trim() !== '') {
            label.classList.add(toggleClass);
        }
    });
}

const handleSelectInputLabel = () => {
    const selectsFields: NodeListOf<HTMLElement> = document.querySelectorAll('.selectField');
    const toggleClass: string = '-translate-y-[1.75rem]';
    selectsFields.forEach((select:HTMLElement) => {
        const input = select.querySelector('select');
        input?.addEventListener('focus', () => {
            const label = select.querySelector('label') as HTMLElement;
            label.classList.add(toggleClass);
        })
    });
}

document.addEventListener('DOMContentLoaded', () => {
    handleTextInputLabel()
    handleSelectInputLabel()
});