import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export class OffersSlider {
    private sliderContainer: HTMLElement | null;

    constructor() {
        this.sliderContainer = document.querySelector('#homepageSliderOffers')

        if(!this.sliderContainer) return;

        this.setupOffersSlider()
    }

    private setupOffersSlider(): void {
        new Splide('#homepageSliderOffers', {
            type: 'loop',
            mediaQuery: 'min',
            classes: {
                pagination: 'splide__pagination !-bottom-8',
                page      : 'splide__pagination__page slider-pagination-items',
            },
        }).mount();
    }

    public static initSliders(): void {
        document.addEventListener('DOMContentLoaded', () => {
            new OffersSlider();
        });
    };
}

OffersSlider.initSliders();
