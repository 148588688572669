$(function () {
    $(".single_bar_button").click(function () {
        $(".single_menu").toggleClass("single_menu-open");
    });

    $(".une").on("init", function (event, slick, direction) {
        if ($(window).width() > 992) {
            $(".une .div").css({
                display: "flex",
            });
        } else {
            $(".une .div_content").each(function () {
                $(this).css({
                    paddingBottom: "80px",
                });
            });
        }
        $('.une .slick-track').height($('.une .div').height() + 4);
        $('.une .slick-track .div_image').removeAttr('style');
    });

    // $(".une").slick({
    //     dots: true,
    //     arrows: false,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 1,
    //     initialSlide: 0,
    //     lazyLoad: "progressive",
    //     slidesToScroll: 1,
    // });

    $(window).resize(function () {
        if ($(window).width() > 992) {
            $(".une .div").css({
                display: "flex",
            });
        } else {
            $(".une .div").css({
                display: "block",
            });
            $(".une .div_content").each(function () {
                $(this).css({
                    paddingBottom: "80px",
                });
            });
        }
        $('.une .slick-track').height($('.une .slick-current').height() + 4);
        $('.une .slick-track .div_image').removeAttr('style');
    });

    $("body").on("click", ".slick-dots button", function () {
        $(".une .slick-track").height($(".une .slick-current").height() + 4);
    });
});
