import axios from 'axios'
import { displayFormSubmitMessage, toggleDisableSubmitBtn } from '@/utils/forms';

document.addEventListener("DOMContentLoaded", function () {
    const form = document.querySelector(".contact-form");

    const submitButton: HTMLElement | null = document.querySelector(".submitContactForm");

    document.querySelectorAll('.openContactForm')?.forEach(item => {
        item?.addEventListener('click', () => {
            form?.classList.toggle('hidden');
        })
    });

    document.querySelector('#closeContactForm')?.addEventListener('click', () => {
        form?.classList.add('hidden');
    })

    form?.addEventListener('submit', (event) => {
        console.log('submit contact form')
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        submitForm(formData);
    })

    const submitForm = async (formData: FormData) => {
        try {
            toggleDisableSubmitBtn(submitButton, true);
            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');

            console.log('response status from contact form', response.status);
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé');

        } catch (error) {
            console.error(error);
            displayFormSubmitMessage('error', 'Une erreur est survenue, merci de rééssayer plus tard');
        } finally {
            toggleDisableSubmitBtn(submitButton, false);
        }
    }
});
