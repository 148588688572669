import axios from 'axios'
import { gtmEvent } from '../../../scripts/includes/gtm'
import { displayFormSubmitMessage, toggleDisableSubmitBtn } from '@/utils/forms';

document.addEventListener('DOMContentLoaded', () => {
    const formModal: HTMLElement | null = document.querySelector('.vendreTerrainFormModal')
    const form: HTMLElement | null = document.querySelector('#contactform_ground')
    const submitBtn: HTMLElement | null = document.querySelector('.submitTerrainFormBtn')
    const openVendreTerrainFormBtn: NodeListOf<HTMLElement> | null = document.querySelectorAll('.openFormModalBtn')
    const closeVendreTerrainFormBtn: HTMLElement | null = document.querySelector('#closeTerrainForm')

    if(!formModal) return;

    openVendreTerrainFormBtn?.forEach(btn => {
        btn.addEventListener('click', () => {
            formModal.classList.toggle('hidden');
        })
    })

    closeVendreTerrainFormBtn?.addEventListener('click', () => {
        formModal.classList.add('hidden');
    })

    const submitTerrainForm = async (formData: FormData) => {
        try {
            toggleDisableSubmitBtn(submitBtn, true);

            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');

            console.log('response from submit terrain form', response.status);
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé');
            gtmEvent('Lead', {
                form: {
                    id: "Terrain à vendre",
                    title: "Terrain à vendre"
                },
                success: true
            });

        } catch (error) {
            console.error('Error submitting terrain form', error);
            displayFormSubmitMessage('error', 'Une erreur est survenue lors de la soumission du formulaire.');
            gtmEvent('Lead', {
                form: {
                    id: "Terrain à vendre",
                    title: "Terrain à vendre"
                },
                success: false
            });
        } finally {
            toggleDisableSubmitBtn(submitBtn, false);
        }
    }

    form?.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        if(!formData) return;
        submitTerrainForm(formData);
    })
})