document.addEventListener("DOMContentLoaded", function () {
    const programHeaderSlider = document.querySelector("#programHeaderSlider") as HTMLElement;
    const menuItem = document.querySelector(".menu_item-programs") as HTMLElement;
    if (programHeaderSlider && menuItem) {
        window.addEventListener("scroll", function () {
            const headerBottomPosition: number = programHeaderSlider.offsetTop + programHeaderSlider.offsetHeight;
            const scrollTop: number = window.scrollY;
            if (scrollTop >= (headerBottomPosition - 100)) {
                menuItem.classList.remove("menu_item-programs-inactive");
                menuItem.classList.add("menu_item-programs-active");
            }
            else {
                menuItem.classList.remove("menu_item-programs-active");
                menuItem.classList.add("menu_item-programs-inactive");
            }
        });
    }
});
