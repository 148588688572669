import axios from 'axios'
import { gtmEvent } from '../../scripts/includes/gtm';
import { displayFormSubmitMessage, toggleDisableSubmitBtn } from '@/utils/forms';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;

const getFormAttributes = (element: HTMLElement) => {
    const form: HTMLElement | null = element.querySelector('form');
    if(!form) return;

    gtmIdOperation = form.getAttribute('data-program-id-operation');
    gtmProgramName = form.getAttribute('data-program-name');
}

const setGtmLeadEvent = (success: boolean) => {
    gtmEvent('Lead', {
        form: {
            id: "Rappel form",
            title: "Rappel form"
        },
        program: {
            id: gtmIdOperation ?? '',
            title: gtmProgramName ?? '',
        },
        success: success
    });
}

// TODO: je suppose qu'il y a la logique autour de l'envoi du plan à faire
document.addEventListener("DOMContentLoaded", function () {
    const form: HTMLElement | null = document.querySelector(".plan-form");

    if(!form) return;

    getFormAttributes(form);
    
    const submitButton: HTMLElement | null = document.querySelector(".submitPlanForm");

    document.querySelectorAll('.openPlanForm')?.forEach(item => {
        item?.addEventListener('click', () => {
            form?.classList.toggle('hidden');
        })
    });

    document.querySelector('#closePlanForm')?.addEventListener('click', () => {
        form?.classList.add('hidden');
    })

    form?.addEventListener('submit', (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        submitForm(formData);
        // console.log("submitting contact form", formData.entries().toArray());
    })

    const submitForm = async (formData: FormData) => {
        try {
            toggleDisableSubmitBtn(submitButton, true);
            if(form) console.log('getFormAttributes(form)', getFormAttributes(form))

            // @ts-ignore
            const response = await axios.post(urls.ajax, formData);

            if(response.status !== 200) throw new Error('Failed to submit form');
            console.log('response status from plan form', response.status);
            
            displayFormSubmitMessage('success', 'Votre message a bien été envoyé');
            setGtmLeadEvent(true);
        } catch (error) {
            console.error(error);
            setGtmLeadEvent(false);
            displayFormSubmitMessage('error', 'Une erreur est survenue, merci de réessayer plus tard');

        } finally {
            toggleDisableSubmitBtn(submitButton, false);
        }
    }
});
