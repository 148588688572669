<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SearchFormHomeMobile from '@/vue/forms/SearchFormHomeMobile.vue';

const formIsOpen = ref<boolean>(false)
const isMobile = ref<boolean>(false);

onMounted(() => {
    isMobile.value = window.innerWidth < 768;
    window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
    });
});

const handleDisplaySearchForm = () => {
    formIsOpen.value = !formIsOpen.value
}

</script>

<template>
        <button
            @click="handleDisplaySearchForm"
            class="relative border border-primary rounded-md p-2 inline-flex gap-2 items-center w-full"
            role="button"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <circle cx="12.5" cy="12.5" r="12.5" fill="#640A32" />
                <path
                    d="M11.5737 14.8149C13.3636 14.8149 14.8145 13.3639 14.8145 11.5741C14.8145 9.7843 13.3636 8.33337 11.5737 8.33337C9.78394 8.33337 8.33301 9.7843 8.33301 11.5741C8.33301 13.3639 9.78394 14.8149 11.5737 14.8149Z"
                    stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.9716 16.8981L13.6221 14.5486" stroke="white" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <p class="text-primary">Trouvez votre logement</p>
        </button>

        <KeepAlive>
            <Teleport to="body">
                <SearchFormHomeMobile
                    v-if="isMobile"
                    :formIsOpen="formIsOpen"
                    @handleDisplaySearchForm="handleDisplaySearchForm"
                />
            </Teleport>
    </KeepAlive>
</template>
