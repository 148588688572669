<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia';
import { useSearchForm } from '@/composables/useSearchForm';

import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';

import { useSearchFormStore } from '@/stores/searchFormStore';
const searchStore = useSearchFormStore();
const { formPayload } = storeToRefs(searchStore);

const {
    loading,
    programsCount,
    loadPrograms,
    setSearchUrl,
    formatSearchResults
} = useSearchForm();

const emits = defineEmits(['handleSubmitSearch', 'displayMoreFilters'])

const displayHiddenFilters = ref(false);

const handleDisplayFilters = () => {
    displayHiddenFilters.value = !displayHiddenFilters.value;
}

watch(() => formPayload.value, () => {
    searchStore.updateSearchQuery();
}, { deep: true });

onMounted(() => {
    searchStore.resetFormPayload();
    loadPrograms();
})
</script>

<template>
    <div class="absolute lg:bottom-10 w-[90dvw] z-100 left-1/2 -translate-x-1/2">

        <form @submit.prevent="setSearchUrl" class="max-lg:hidden grid grid-cols-4 items-end gap-x-4 gap-y-6 bg-white p-6 rounded-md">
            <PlacesField
                v-model="formPayload.places"
            />
            <TypesField
                v-model="formPayload.types"
            />
            <PriceField
                v-model="formPayload.price"
            />

            <div v-if="!displayHiddenFilters" class="flex">
                <button
                    type="submit"
                    class="p-5 bg-primary text-white grow font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2 justify-center"
                    @submit.prevent="setSearchUrl"
                >
                    <span class="animate-pulse" v-if="loading">Chargement</span>
                    <span v-else class="whitespace-nowrap">
                        {{  formatSearchResults(programsCount) }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>

            <div v-if="displayHiddenFilters" class="col-span-4 grid grid-cols-4 gap-4">
                <RoomsField
                    v-model="formPayload.rooms"
                />

                <FiscalField
                    v-model="formPayload.dispositifs"
                />

                <AvancementField
                    v-model="formPayload.status"
                />

                <div v-if="displayHiddenFilters" class="flex">
                <button
                    type="submit"
                    class="p-5 bg-primary text-white grow font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2 justify-center"
                    @submit.prevent="setSearchUrl"
                >
                    <span class="animate-pulse" v-if="loading">Chargement</span>
                    <span v-else class="whitespace-nowrap">
                        {{ formatSearchResults(programsCount) }}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>
            </div>
        </form>

        <button
            @click="handleDisplayFilters"
            type="button"
            class="max-lg:hidden mt-4 left-[5dvw] p-2 bg-red text-white font-regular text-base uppercase leading-none rounded-md inline-flex items-center gap-2 z-100"
        >
            <span class="btnContent">
                {{ displayHiddenFilters ? 'Moins de filtres' : 'Recherche avancée' }}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
        </button>
</div>

</template>
