<script setup lang="ts">
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core'

import { useSearchFormStore } from '@/stores/searchFormStore';
const {
    dispositifsList,
 } = useSearchFormStore();

const model = defineModel({
    default: [] as string[],
});

type DispositifItem = {
  key: string;
  value: string;
};

type DispositifsList = DispositifItem[];

const focus = ref(false);
const container = ref(null);
const search = ref('');
const results = ref(dispositifsList as DispositifsList);

const displayLabel = computed(() => {
    return model.value.join(', ');
});

const toggleItemOnModel = (result: string) => {
    if (!model.value.find((item) => item === result)) {
        model.value = [...model.value, result];
    } else {
        model.value = model.value?.filter((item) => item !== result);
    }
}

onClickOutside(container, () => {
    focus.value = false;
    search.value = '';
});
</script>

<template>
    <div ref="container" class="mt-3 flex flex-col relative">
        <label
            for="fiscal"
            :class="`flex justify-between w-full items-center uppercase text-dark/60 absolute z-10 ${focus || search || model.length ? 'text-xs -top-3' : 'text-base top-2.5'} transition-all duration-150 ease-in-out`"
        >
            Dispositif fiscal
            <svg
                xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"
            >
                <path d="M1 1L5 5L9 1" stroke="#630A32" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        </label>
        <div v-if="!focus && model.length" class="w-full absolute py-2 bg-white text-nowrap truncate pointer-events-none">
            {{ displayLabel }}
        </div>
        <input
            id="fiscal"
            name="fiscal"
            type="text"
            class="border-b-2 border-secondary py-2 outline-none"
            @focus="focus = true"
            v-model="search"
            autocomplete="off"
        >
        <div v-if="focus" class="w-full absolute top-11 bg-white z-60 shadow max-h-64 overflow-y-auto">
            <ul class="w-full px-4 divide-y divide-secondary">
                <li v-for="(result, index) in results" :key="index" @click="toggleItemOnModel(result.value)">
                    <div class="p-3 pl-7 relative flex items-center gap-3 cursor-pointer hover:bg-primary/10">
                        <svg v-if="model.find(item => item === result.value)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-primary absolute left-0">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        {{ result.key }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
