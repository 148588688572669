document.addEventListener('DOMContentLoaded', () => {
    const homepageSearchFormMobile: HTMLElement | null = document.querySelector('#homepageSearchFormMobile');
    const openSearchFormMobileBtn: HTMLElement | null = document.querySelector('#openHomepageMobileSearchForm');
    const closeHomepageMobileSearchForm: HTMLElement | null = document.querySelector('#closeHomepageMobileSearchForm');
    const displayMoreFiltersBtn: HTMLElement | null = document.querySelector('.displayMoreFiltersBtn');
    const hiddenFormFields: HTMLElement | null = document.querySelector('.hiddenFormFields');

    if(openSearchFormMobileBtn && homepageSearchFormMobile) {
        openSearchFormMobileBtn.addEventListener('click', () => {
            homepageSearchFormMobile.classList.toggle('hidden');
        });
    }

    if(closeHomepageMobileSearchForm && homepageSearchFormMobile) {
        closeHomepageMobileSearchForm.addEventListener('click', () => {
            homepageSearchFormMobile.classList.add('hidden');
        });
    }

    if(displayMoreFiltersBtn && hiddenFormFields) {
        const btnContent: HTMLElement | null = displayMoreFiltersBtn.querySelector('.btnContent');
        displayMoreFiltersBtn.addEventListener('click', () => {
            hiddenFormFields.classList.toggle('hidden');

            if(btnContent) btnContent.textContent = hiddenFormFields.classList.contains('hidden') ? 'Plus de filtres' : 'Moins de filtres';
        });
    }

    homepageSearchFormMobile?.addEventListener('submit', (e: Event) => {
        e.preventDefault();
        console.log('submitting form');
    })
})
