export const displayFormSubmitMessage = (type: 'success' | 'error', message: string) => {
    const messageContainer: HTMLElement | null = document.querySelector('.submit_form_message');

    if(!messageContainer) return;

    messageContainer.classList.remove('hidden');
    messageContainer.classList.add(type === 'success' ? 'bg-green' : 'bg-red');
    messageContainer.innerText = message;

    setTimeout(() => {
        messageContainer.classList.add('hidden');
    }, 5000);
}

export const toggleDisableSubmitBtn = (submitButton: HTMLElement | null, isDisabled: boolean) => {
    submitButton?.setAttribute('disabled', isDisabled ? 'disabled' : '');

    if (isDisabled) {
        submitButton?.classList.add('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.remove('cursor-pointer', 'opacity-100');
    } else {
        submitButton?.classList.remove('cursor-not-allowed', 'opacity-50');
        submitButton?.classList.add('cursor-pointer', 'opacity-100');
    }
};

export function debounce<F extends (...args: Parameters<F>) => void>(func: F, timeout: number) {
	let timeoutId: ReturnType<typeof setTimeout>;

	return (...args: Parameters<F>): void => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}
