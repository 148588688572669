import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// @ts-ignore
const hideMenuOnScroll = () => {
    const menu: HTMLElement | null = document.querySelector("#menu_topbar");
    const offer_banner: HTMLElement | null = document.querySelector(".offer_homepage_banner");
    
    if(!menu || !offer_banner) return;

    const showAnim = gsap
            .from(menu, {
                top: '-100%',
                pointerEvents: "none",
                paused: true,
            })
            .progress(1);

    ScrollTrigger.create({
        start: "700px top",
        onUpdate: (self) => {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
    });


}

const revealBlocksAnimation = (items: NodeListOf<HTMLElement>) => {
    items.forEach((element: HTMLElement) => {
        gsap.from(element, {
            scrollTrigger: {
                trigger: element,
                start: "top 80%",
            },
            y: 50,
            opacity: 0,
            duration: 1,
            ease: "power4.out",
        });
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Register ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);

    const revealElements: NodeListOf<HTMLElement> = document.querySelectorAll(".gsap-reveal");

    if(!revealElements) return;

    revealBlocksAnimation(revealElements);
    // hideMenuOnScroll();
});