// Format results count phrase
export const formatSearchResults = (count: number) => {
    switch (count) {
        case 0:
            return 'Aucun résultat';
        case 1:
            return 'Voir le résultat';
        default:
            return `Voir les ${count} résultats`;
    }
};

// Format url with query string
export const formatUrl = (value: string) => {
    const url = new URL(window.location.href);
    url.search = value;
    history.replaceState(null, '', url.toString());
}
