$(function () {
    table_size();

    $(window).resize(function () {
        table_size();
        $(".link_image").each(function () {
            $(this).height(($(this).width() / 16) * 9);
        });
        $(".div_image").each(function () {
            if ($(window).width() > 992) {
                $(this).height($(this).next().outerHeight());
            }
        });
    });

    $(".modal_close, .modal_overlay").click(function () {
        $(".modal").addClass("hidden");
        $(".modal_overlay").addClass("hidden");
        $(".modal_close").removeClass("text-white");
        $(".modal_close .modal_icon").removeClass("fill-white");
        $(".contactform_field-message").addClass("hidden");
        $(".contactform_brochure_message").addClass("hidden");
        $(".contactform_plan_message").addClass("hidden");
        $(".contactform_form").removeClass("hidden");
        $("body").removeClass("overflow-hidden");
    });

    $('img[src$=".svg"]').each(function () {
        var $img = $(this);
        var imgID = $img.attr("id");
        var imgClass = $img.attr("class");
        var imgURL = $img.attr("src");

        $.get(
            imgURL,
            function (data) {
                var $svg = $(data).find("svg");
                if (typeof imgID !== "undefined") {
                    $svg = $svg.attr("id", imgID);
                }
                if (typeof imgClass !== "undefined") {
                    $svg = $svg.attr("class", imgClass);
                }
                $svg = $svg.removeAttr("xmlns:a");
                if (
                    !$svg.attr("viewBox") &&
                    $svg.attr("height") &&
                    $svg.attr("width")
                ) {
                    $svg.attr(
                        "viewBox",
                        "0 0 " + $svg.attr("height") + " " + $svg.attr("width"),
                    );
                }
                $img.replaceWith($svg);
            },
            "xml",
        );
    });

    $(".link_image").each(function () {
        $(this).height(($(this).width() / 16) * 9);
    });

    $(".div_image").each(function () {
        if ($(window).width() > 992) {
            $(this).height($(this).next().outerHeight());
        } else {
            $(this).height(384);
        }
    });

    if (
        !$("body").hasClass("post-type-archive-program") &&
        !$("body").hasClass("single-offer") &&
        !$("body").hasClass("error404") &&
        !$("body").hasClass("page-template-page-programs")
    ) {
        $("iframe:not(.not_video)").each(function () {
            if (
                $(this).attr("name") !== "__uspapiLocator" &&
                $(this).attr("src") !==
                    "https://consentcdn.cookiebot.com/sdk/bc-v4.min.html"
            ) {
                $(this).addClass("video");
                if ($(this).hasClass("program_visite_block")) {
                    $(this).wrap('<div class="video_container hidden"></div>');
                } else {
                    $(this).wrap('<div class="video_container"></div>');
                }
            }
        });
    }
});

function table_size() {
    $("#lots")
        .find(".table_container")
        .last()
        .find(".table_heading")
        .first()
        .width(
            $("#lots")
                .find(".table_container")
                .first()
                .find(".table_heading")
                .first()
                .width(),
        );
}

Number.prototype.format = function (n, x, s, c) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")";
    var num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(
        new RegExp(re, "g"),
        "$&" + (s || ","),
    );
};
